<template>
    <div class="p-system-accounts" v-loading="isLoading">
        <div class="hd">
            <div class="page-title">账号管理</div>
            <div class="topbar">
                <div>
                    <el-button type="primary" @click="onCreate"
                        >创建账号</el-button
                    >
                </div>
                <div>
                    <el-input
                        icon="el-icon-search"
                        v-model="search.keyword"
                        placeholder="搜索邮箱或名字"
                    ></el-input>
                </div>
                <div>
                    <el-select v-model="search.status">
                        <el-option
                            v-for="item of statusList"
                            :key="item.value"
                            :label="item.text"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="bd">
            <div class="msg-wrap">
                <el-alert title="提示" type="info" show-icon>
                    <p>默认是"未激活"状态。</p>
                    <p>
                        密码错误3次，账号将被锁定。过一段时间后又可以尝试登录。
                    </p>
                    <p>
                        只有正常状态下的账号和锁定超过一定时间后的账号可以登录。其他状态的账号不可登录。
                    </p>
                </el-alert>
            </div>
            <el-table :data="accounts">
                <el-table-column label="ID" prop="id"></el-table-column>
                <el-table-column
                    label="邮箱地址"
                    prop="email"
                ></el-table-column>
                <el-table-column
                    label="名字"
                    prop="display_name"
                ></el-table-column>
                <el-table-column label="状态">
                    <template scope="scope">
                        <span>{{ scope.row.status | statusStr }}</span
                        >&nbsp;
                        <el-button
                            type="primary"
                            size="mini"
                            plain
                            v-if="scope.row.status === 0"
                            @click="onActive(scope.row)"
                            >激活</el-button
                        >
                        <el-button
                            type="success"
                            size="mini"
                            plain
                            v-if="scope.row.status === 2"
                            @click="onUnlock(scope.row)"
                            >解锁</el-button
                        >
                        <el-button
                            type="warning"
                            size="mini"
                            plain
                            v-if="
                                scope.row.status === 1 || scope.row.status === 2
                            "
                            @click="onDisable(scope.row)"
                            >禁用</el-button
                        >
                        <el-button
                            type="success"
                            size="mini"
                            plain
                            v-if="scope.row.status === 3"
                            @click="onEnable(scope.row)"
                            >启用</el-button
                        >
                        <el-button
                            type="danger"
                            size="mini"
                            plain
                            v-if="false"
                            @click="onDelete(scope.row)"
                            >删除</el-button
                        >
                        <el-button
                            type="success"
                            size="mini"
                            plain
                            v-if="scope.row.status === 4"
                            @click="onUnlock(scope.row)"
                            >恢复</el-button
                        >
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template scope="scope">
                        <el-button size="mini" plain @click="onEdit(scope.row)"
                            >修改</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="ft"></div>
        <el-dialog title="创建账号" :visible.sync="createFormVisible">
            <el-form
                label-width="60px"
                :model="createForm"
                :rules="createFormRules"
                ref="createForm"
            >
                <el-form-item label="邮箱" prop="email">
                    <el-input
                        v-model="createForm.email"
                        placeholder="abc@mmldigi.com"
                    ></el-input>
                </el-form-item>
                <el-form-item label="名字" prop="display_name">
                    <el-input
                        v-model="createForm.display_name"
                        placeholder="Tommy So"
                    ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input
                        v-model="createForm.password"
                        placeholder=""
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <p>密码可修改。</p>
                    <p>请现在复制密码，创建后不再展示。</p>
                    <p>用户可通过找回密码的方式重置密码。</p>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="createFormVisible = false">取消</el-button>
                <el-button type="primary" @click="create">创建</el-button>
            </span>
        </el-dialog>
        <el-dialog title="编辑" :visible.sync="editFormVisible">
            <el-form
                label-width="60px"
                :model="editForm"
                :rules="editFormRules"
                ref="editForm"
            >
                <el-form-item label="邮箱" prop="email">
                    <span v-text="editForm.email ? editForm.email : ''"></span>
                </el-form-item>
                <el-form-item label="名字" prop="display_name">
                    <el-input
                        v-model="editForm.display_name"
                        placeholder="Tommy So"
                    ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input
                        v-model="editForm.password"
                        placeholder="如果不需要修改密码，请留空"
                    ></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="editFormVisible = false">取消</el-button>
                <el-button type="primary" @click="update">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<style lang="less">
.p-system-accounts {
    .hd {
        .topbar {
            display: flex;

            & > div {
                padding-top: 10px;
                padding-right: 10px;
            }
        }
    }

    .bd {
        .msg-wrap {
            padding: 10px 0;
        }
    }
}
</style>

<script>
import Status from "../js/status.js";
import { getRandomString, delayRun } from "../js/tools.js";

export default {
    data() {
        return {
            isLoading: false,
            search: {
                keyword: "",
                status: -1,
            },
            accounList: [],
            createForm: {
                email: "",
                display_name: "",
                password: "",
            },
            createFormRules: {
                email: [
                    {
                        required: true,
                        message: "请填写账号的登录邮箱地址",
                        trigger: "blur",
                    },
                    {
                        min: 13,
                        max: 64,
                        message: "请输入正确的邮箱地址",
                        trigger: "blur",
                    },
                    {
                        validator: function (rule, value, callback) {
                            if (!/.+@mmldigi.com$/.test(value)) {
                                callback(
                                    new Error("仅支持 @mmldigi.com 的邮箱")
                                );
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                display_name: [
                    { required: true, message: "请填写 Name", trigger: "blur" },
                    {
                        min: 1,
                        max: 32,
                        message: "请输入正确的 Name",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请填写登录密码",
                        trigger: "blur",
                    },
                    {
                        min: 6,
                        max: 32,
                        message: "密码长度: 6 ~ 32",
                        trigger: "blur",
                    },
                ],
            },
            createFormVisible: false,
            editForm: {
                id: 0,
                email: "",
                display_name: "",
                password: "",
            },
            editFormRules: {
                email: [
                    {
                        required: true,
                        message: "请填写登录邮箱",
                        trigger: "blur",
                    },
                    {
                        min: 13,
                        max: 64,
                        message: "请输入正确的邮箱地址",
                        trigger: "blur",
                    },
                    {
                        validator: function (rule, value, callback) {
                            if (!/.+@mmldigi.com$/.test(value)) {
                                callback(
                                    new Error("仅支持 @mmldigi.com 的邮箱")
                                );
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                display_name: [
                    { required: true, message: "请填写名字", trigger: "blur" },
                    {
                        min: 1,
                        max: 32,
                        message: "请输入正确的名字",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        min: 6,
                        max: 32,
                        message: "密码长度: 6 ~ 32",
                        trigger: "blur",
                    },
                ],
            },
            editFormVisible: false,
            statusList: Status.AccountStatus.getFilterList("全部状态"),
        };
    },
    computed: {
        accounts() {
            let result = [];
            for (let i = 0; i < this.accounList.length; i++) {
                let item = this.accounList[i];
                if (
                    this.search.keyword &&
                    item.email.indexOf(this.search.keyword) < 0 &&
                    item.display_name.indexOf(this.search.keyword) < 0
                ) {
                    continue;
                }
                if (
                    this.search.status !== -1 &&
                    item.status !== this.search.status
                ) {
                    continue;
                }
                result.push(Object.assign({}, item));
            }
            return result;
        },
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            let that = this;
            that.$ajax("/account/get-list").done((data) => {
                that.accounList = data;
            });
        },
        updateAccountStatus(account, newStatus) {
            let that = this;
            let msg = `确定要把账号 ${
                account.email
            } 的状态修改为 ${Status.AccountStatus.getText(newStatus)} 吗？`;
            this.$confirm(msg)
                .then(function () {
                    var param = { email: account.email, status: newStatus };
                    if (that.isLoading) return;
                    that.isLoading = true;
                    that.$ajax("/account/update-status", param)
                        .done(function () {
                            account.status = newStatus;
                        })
                        .fail(function () {})
                        .always(function () {
                            that.isLoading = false;
                        });
                })
                .catch(() => {
                    // cancel
                });
        },
        onActive(account) {
            // this.$alert('激活 ' + account.email)
            this.updateAccountStatus(account, 1);
        },
        onDisable(account) {
            this.updateAccountStatus(account, 3);
        },
        onUnlock(account) {
            this.updateAccountStatus(account, 1);
        },
        onEnable(account) {
            this.updateAccountStatus(account, 1);
        },
        onDelete() {
            this.$alert("暂时不支持删除功能");
        },
        onRecover() {
            this.$alert("暂时不支持恢复功能");
        },
        onCreate() {
            this.createForm.email = "";
            this.createForm.display_name = "";
            this.createForm.password = "";
            this.createFormVisible = true;
            delayRun(1, () => {
                this.$refs["createForm"].resetFields();
                this.createForm.password = getRandomString(8);
            });
        },
        create() {
            let that = this;
            that.$refs["createForm"].validate((valid) => {
                if (!valid) {
                    return;
                }
                if (that.isLoading) return;
                that.isLoading = true;
                that.$ajax("/account/create", that.createForm)
                    .done(function () {
                        that.isLoading = false;
                        that.createFormVisible = false;
                        that.getList();
                    })
                    .fail(function () {})
                    .always(function () {
                        that.isLoading = false;
                    });
            });
        },
        onEdit(account) {
            this.editFormVisible = true;
            delayRun(1, () => {
                this.$refs["editForm"].resetFields();
                this.editForm.id = account.id;
                this.editForm.email = account.email;
                this.editForm.display_name = account.display_name;
            });
        },
        update() {
            let that = this;
            that.$refs["editForm"].validate((valid) => {
                if (!valid) {
                    return;
                }
                if (that.isLoading) return;
                that.isLoading = true;
                that.$ajax("/account/update", that.editForm)
                    .done(function () {
                        that.isLoading = false;
                        that.editFormVisible = false;
                        that.getList();
                    })
                    .fail(function () {})
                    .always(function () {
                        that.isLoading = false;
                    });
            });
        },
    },
    filters: {
        statusStr(status) {
            return Status.AccountStatus.getText(status, "未知状态: " + status);
        },
    },
};
</script>
