
class Status {
	constructor (list) {
		this.list = list
	}
	getText (value, defaultValue = '') {
		let result = defaultValue
		for (let i = 0; i < this.list.length; i++) {
			let item = this.list[i]
			if (item.value === value) {
				result = item.text
			}
		}
		return result
	}
	getFilterList (defaultText = '', defaultValue = -1) {
		let result = []
		if (defaultText) {
			result.push({ text: defaultText, value: defaultValue })
		}
		for (let i = 0; i < this.list.length; i++) {
			let item = this.list[i]
			result.push({ text: item.text, value: item.value })
		}
		return result
	}
}

let accountStatusList = [
	{ text: '未激活', value: 0 },
	{ text: '正常', value: 1 },
	{ text: '已锁定', value: 2 },
	{ text: '已禁用', value: 3 },
	{ text: '已删除', value: 4 },
]

let projectStatusList = [
	{ text: '未开始', value: 0 },
	{ text: '前期资料完成', value: 1 },
	{ text: '进行中', value: 2 },
	{ text: '已上线', value: 3 },
	{ text: '异常', value: 4 },
	{ text: '已结束合作', value: 5 },
]

module.exports = {
	AccountStatus: new Status(accountStatusList),
	ProjectStatus: new Status(projectStatusList),
}
